<template>
  <div :class="'p-fluid ' + (margin || 'mb-3')">
    <div v-if="type === 'select'" class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <Dropdown
        :inputId="fieldStr"
        v-model="serviceF"
        :filter="filter"
        :filterMatchMode="filterMatchMode"
        :filterFields="filterFields"
        @filter="onFilter($event)"
        :options="typeof options === 'function' ? options(service) : options"
        :placeholder="placeholder"
        :showClear="showClear"
        :optionValue="optionValue || 'id'"
        :optionLabel="optionLabel || 'name'"
        :optionGroupLabel="optionGroupLabel"
        :optionGroupChildren="optionGroupChildren"
        @change="
          onInput($event, fieldStr);
          $emit('change', $event);
        "
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
        :editable="editable"
        :panelClass="panelClass"
      >
        <template #optiongroup="data">
          <slot name="optiongroup" :data="data"></slot>
        </template>
        <template #option="data">
          <slot name="option" :data="data"></slot>
        </template>
        <template #value="data">
          <slot name="value" :data="data"></slot>
        </template>
      </Dropdown>
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'multiselect'" class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <MultiSelect
        :inputId="fieldStr"
        v-model="serviceF"
        :options="options"
        :optionValue="optionValue || 'id'"
        :optionLabel="optionLabel || 'name'"
        :optionGroupLabel="optionGroupLabel"
        :optionGroupChildren="optionGroupChildren"
        :maxSelectedLabels="2"
        selectedItemsLabel="Выбрано {0}"
        @change="
          onInput($event, fieldStr);
          $emit('change', $event);
        "
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
        :autoOptionFocus="false"
      >
        <template #optiongroup="data">
          <slot name="optiongroup" :data="data"></slot>
        </template>
        <template #option="data">
          <slot name="option" :data="data"></slot>
        </template>
        <template #value="slotProps">
          <slot name="value" :data="slotProps"></slot>
        </template>
        <template #footer="data">
          <slot name="footer" :data="data"></slot>
        </template>
        <template #content="data">
          <slot name="content" :data="data"></slot>
        </template>
      </MultiSelect>
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'checkbox'" class="p-field-checkbox">
      <Checkbox
        :id="fieldStr"
        v-model="serviceF"
        :binary="true"
        @input="onInput($event, fieldStr)"
        :falseValue="falseValue || null"
        :trueValue="trueValue || null"
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
      />
      <label v-if="label" class="ms-1" :for="fieldStr">{{ label }}</label>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
    </div>

    <div v-else-if="type === 'radio'" class="p-field">
      <label v-if="label">{{ label }}</label
      ><br />
      <span v-for="(option, i) of options" class="p-field-radiobutton" :key="i">
        <RadioButton
          :id="fieldStr + '-' + i"
          v-model="serviceF"
          :name="fieldStr"
          :value="option[optionValue || 'id']"
          @change="onInput($event, fieldStr)"
          :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
          :disabled="disabled"
        />
        <label class="ms-1 me-3" :for="fieldStr + '-' + i">
          {{ option[optionLabel || 'name'] }}
        </label>
        <div v-if="i === options.length - 1 && service.errors[fieldStr]" class="invalid-feedback">
          {{ service.errors ? service.errors[fieldStr] : null }}
        </div>
      </span>
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
    </div>

    <div v-else-if="type === 'switch'" class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <div class="mt-2">
        <InputSwitch
          :id="fieldStr"
          v-model="serviceF"
          @input="onInput($event, fieldStr)"
          @change="
            onInput($event, fieldStr);
            $emit('change', $event);
          "
          :falseValue="falseValue || 0"
          :trueValue="trueValue || 1"
          :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        />
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
    </div>

    <div v-else-if="type === 'textarea'" class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <Textarea
        :id="fieldStr"
        v-model="serviceF"
        :autoResize="true"
        @input="onInput($event, fieldStr)"
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :placeholder="placeholder"
        :disabled="disabled"
      />
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'password'" class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <Password
        :id="fieldStr"
        v-model="serviceF"
        @input="onInput($event, fieldStr)"
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
        :toggleMask="toggleMask"
        :feedback="feedback"
      />
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'mask'" class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <div
        :class="
          [appendBtn || prependBtn || isPrependAddon || isAppendAddon ? 'p-inputgroup' : ''] +
          (service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid p-inputgroup' : '')
        "
      >
        <span v-if="isPrependAddon" class="p-inputgroup-addon">
          <div>
            <slot name="prependAddon"></slot>
          </div>
        </span>
        <Button v-if="prependBtn" @click="$emit('btnClick', event)" :icon="iconBtn" :class="classBtn" />
        <InputMask
          :id="fieldStr"
          v-model="serviceF"
          @input="onInput($event, fieldStr)"
          :mask="mask"
          :slotChar="slotChar"
          :placeholder="placeholder"
          :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
          :disabled="disabled"
        />
        <Button
          v-if="appendBtn"
          type="submit"
          @click.prevent="$emit('btnClick', event)"
          :icon="iconBtn"
          :class="classBtn"
        />
        <span v-if="isAppendAddon" class="p-inputgroup-addon">
          <slot name="appendAddon"></slot>
        </span>
      </div>
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'number'" class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <div
        :class="[
          appendBtn || prependBtn || isPrependAddon || isAppendAddon ? 'p-inputgroup' : '',
          iconLeft ? 'p-icon-field p-icon-field-left' : '',
          iconRight ? 'p-icon-field p-icon-field-right' : '',
          service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid p-inputgroup' : '',
        ]"
      >
        <span v-if="isPrependAddon" class="p-inputgroup-addon">
          <div>
            <slot name="prependAddon"></slot>
          </div>
        </span>
        <Button v-if="prependBtn" @click="$emit('btnClick', event)" :icon="iconBtn" :class="classBtn" />
        <InputIcon v-if="iconLeft" :class="iconLeft" />
        <InputNumber
          :id="fieldStr"
          v-model="serviceF"
          mode="decimal"
          :maxFractionDigits="2"
          :disabled="disabled"
          @input="onInput($event, fieldStr)"
          :placeholder="placeholder"
          locale="ru-RU"
          :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
          :min="min"
          :max="max"
          inputId="minmax"
        />
        <InputIcon v-if="iconRight" :class="iconRight" />
        <Button v-if="appendBtn" type="submit" @click="$emit('btnClick', event)" :icon="iconBtn" :class="classBtn" />
        <span v-if="isAppendAddon" class="p-inputgroup-addon">
          <slot name="appendAddon"></slot>
        </span>
      </div>
      <div v-if="helpText">
        <div class="helpText">{{ helpText }}</div>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <CalendarField
      v-else-if="type === 'calendar'"
      :service="service"
      :field="field"
      :label="label"
      :disabled="disabled"
      :selectionMode="selectionMode"
      :showTime="showTime"
      :showSeconds="showSeconds"
      :helpText="helpText"
      :tooltip="tooltip"
      :view="view"
      @change="
        $emit('input', $event);
        $emit('change', $event);
      "
    />

    <div v-else-if="type === 'autocomplete'" class="p-field">
      <IconField iconPosition="left">
        <InputIcon v-if="isSearchIcon" class="pi pi-search"> </InputIcon>
        <label v-if="label" :for="fieldStr">{{ label }}</label>
        <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
        <AutoComplete
          :id="fieldStr"
          v-model="service['_' + fieldStr]"
          @input="onInput($event, fieldStr)"
          :suggestions="suggestions"
          @complete="$emit('complete', $event)"
          :class="
            service.errors && service.errors[fieldStr]
              ? 'p-invalid is-invalid ' + classField + ' ' + (isSearchIcon ? 'autocomplete-search-icon' : '')
              : classField + ' ' + (isSearchIcon ? 'autocomplete-search-icon' : '')
          "
          :disabled="disabled"
          :field="optionLabel"
          :placeholder="placeholder"
          :forceSelection="forceSelection"
          :optionLabel="optionLabel"
          :optionGroupLabel="optionGroupLabel"
          :optionGroupChildren="optionGroupChildren"
          :completeOnFocus="completeOnFocus"
          :autoOptionFocus="autoOptionFocus"
          :selectOnFocus="selectOnFocus"
          :panelClass="panelClass"
          :delay="1000"
          @item-select="
            ({ value }) => {
              serviceF = value[optionValue];
              $emit('change');
            }
          "
        >
          <template #item="data">
            <slot name="item" :data="data"></slot>
          </template>
          <template #optiongroup="data">
            <slot name="optiongroup" :data="data"></slot>
          </template>
          <template #footer="data">
            <slot name="footer" :data="data"></slot>
          </template>
          <template #content="data">
            <slot name="content" :data="data"></slot>
          </template>
          <template #empty>
            <div class="p-3">
              {{ $t('Not found') }}
            </div>
          </template>
        </AutoComplete>
      </IconField>
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'color'" class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <ColorPicker
        v-model="serviceF"
        :inline="inline"
        :class="[service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : '', 'd-block']"
        :disabled="disabled"
      />
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'chips'" class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <Chips
        :id="fieldStr"
        v-model="serviceF"
        @input="onInput($event, fieldStr)"
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
      />
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'selectbtn'" class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <SelectButton
        :id="fieldStr"
        v-model="serviceF"
        @change="onInput($event, fieldStr)"
        :options="options"
        :placeholder="placeholder"
        :optionValue="optionValue || 'id'"
        :optionLabel="optionLabel || 'name'"
        :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
        :disabled="disabled"
        :panelClass="panelClass"
      >
        <template #option="data">
          <slot name="option" :data="data"></slot>
        </template>
      </SelectButton>
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <div v-else-if="type === 'editor'" class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <Editor v-if="editorType == 'default'" v-model="serviceF" editorStyle="height: 400px" />
      <Editor v-else v-model="serviceF" editorStyle="height: 160px">
        <template v-slot:toolbar>
          <span class="ql-formats">
            <button class="ql-bold" v-tooltip.bottom="$t('Bold')"></button>
            <button class="ql-italic" v-tooltip.bottom="$t('Italic')"></button>
            <button class="ql-underline" v-tooltip.bottom="$t('Underline')"></button>
            <button class="ql-list" v-tooltip.bottom="$t('Bulleted list')" value="bullet" type="button"></button>
            <button class="ql-list" v-tooltip.bottom="$t('Numbered list')" value="ordered" type="button"></button>
            <button class="ql-clean" v-tooltip.bottom="$t('Clear formatting')" value="clean" type="button"></button>
          </span>
        </template>
      </Editor>
      <div v-if="helpText">
        <div class="helpText">{{ helpText }}</div>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>

    <FileField
      v-else-if="type === 'file'"
      :service="service"
      :field="field"
      :label="label"
      :multiple="multiple"
      :url="url"
      :helpText="helpText"
      :tooltip="tooltip"
      :isGalleria="isGalleria"
      :chooseLabel="chooseLabel"
      :adaptiveClass="adaptiveClass"
    />

    <div v-else class="p-field">
      <label v-if="label" :for="fieldStr">{{ label }}</label>
      <small v-if="tooltip" v-tooltip.right="tooltip" class="ms-1"><i class="bi bi-question-circle"></i></small>
      <div
        :class="[
          appendBtn || prependBtn || isPrependAddon || isAppendAddon ? 'p-inputgroup' : '',
          iconLeft ? 'p-icon-field p-icon-field-left' : '',
          iconRight ? 'p-icon-field p-icon-field-right' : '',
          service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid p-inputgroup' : '',
        ]"
      >
        <span v-if="isPrependAddon" class="p-inputgroup-addon">
          <div>
            <slot name="prependAddon"></slot>
          </div>
        </span>
        <InputIcon v-if="iconLeft" :class="iconLeft" />
        <Button v-if="prependBtn" @click="$emit('btnClick', event)" :icon="iconBtn" :class="classBtn" />
        <InputText
          :id="fieldStr"
          :type="type || 'text'"
          v-model="serviceF"
          @input="onInput($event, fieldStr)"
          :class="service.errors && service.errors[fieldStr] ? 'p-invalid is-invalid' : ''"
          :disabled="disabled"
          autofocus
          :placeholder="placeholder"
        />
        <Button v-if="appendBtn" type="submit" @click="$emit('btnClick', event)" :icon="iconBtn" :class="classBtn" />
        <InputIcon v-if="iconRight" :class="iconRight" />
        <span v-if="isAppendAddon" class="p-inputgroup-addon">
          <slot name="appendAddon"></slot>
        </span>
      </div>
      <div v-if="helpText">
        <p class="helpText">{{ helpText }}</p>
      </div>
      <div v-if="service.errors && service.errors[fieldStr]" class="invalid-feedback">
        {{ service.errors ? service.errors[fieldStr] : null }}
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Checkbox from 'primevue/checkbox';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import Password from 'primevue/password';
import InputMask from 'primevue/inputmask';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import AutoComplete from 'primevue/autocomplete';
import Chips from 'primevue/chips';
import SelectButton from 'primevue/selectbutton';
import InputText from 'primevue/inputtext';
import ColorPicker from 'primevue/colorpicker';
import Editor from 'primevue/editor';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import CalendarField from './CalendarField.vue';
import FileField from './FileField.vue';

export default {
  components: {
    Button,
    InputText,
    Dropdown,
    MultiSelect,
    Checkbox,
    RadioButton,
    Textarea,
    Password,
    InputMask,
    InputSwitch,
    InputNumber,
    Calendar,
    AutoComplete,
    Chips,
    SelectButton,
    ColorPicker,
    Editor,
    IconField,
    InputIcon,
    CalendarField,
    FileField,
  },
  props: {
    service: Object,
    type: String,
    field: String | Array,
    label: String,
    options: Array,
    optionValue: String,
    optionLabel: String,
    mask: String,
    maxlength: Number,
    slotChar: String,
    suggestions: Array,
    disabled: Boolean,
    inline: Boolean,
    filter: Boolean,
    filterMatchMode: String,
    filterFields: Array,
    showClear: { type: Boolean, default: true },
    falseValue: String,
    trueValue: String,
    editable: Boolean,
    panelClass: String,
    multiple: Boolean,
    margin: String,
    optionGroupLabel: String,
    optionGroupChildren: String,
    stepNumber: Number,
    url: String,
    helpText: String,
    placeholder: String,
    selectionMode: String,
    tooltip: String,
    prependBtn: Boolean,
    appendBtn: Boolean,
    forceSelection: Boolean,
    toggleMask: Boolean,
    feedback: Boolean,
    showTime: Boolean,
    showSeconds: Boolean,
    is24hr: Boolean,
    isRange: Boolean,
    iconBtn: String,
    classBtn: String,
    classField: String,
    modelDatePicker: String,
    isGalleria: { type: Boolean, default: true },
    completeOnFocus: Boolean,
    autoOptionFocus: Boolean,
    selectOnFocus: Boolean,
    isSearchIcon: Boolean,
    chooseLabel: String,
    isPrependAddon: Boolean,
    isAppendAddon: Boolean,
    adaptiveClass: String,
    view: String,
    iconLeft: String,
    iconRight: String,
    min: Number,
    max: Number,
    editorType: String,
  },
  emits: ['complete', 'input', 'change', 'btnClick'],
  computed: {
    fieldStr() {
      return this.$arr.toStr(this.field);
    },
    serviceF: {
      get() {
        return this.$obj.getField(this.service, this.field);
      },
      set(v) {
        this.$obj.setField(this.service, this.field, v);
      },
    },
  },
  methods: {
    onFilter(event) {
      this.service.filterValue = event.value;

      this.$emit('filter', event);
    },
    onInput(event, fieldStr) {
      if (event && event.value) {
        // нужно для inputNumber, т.к. там не сразу обновляется реальное значение
        this.serviceF = event.value;
      }

      if (this.service.errors && this.service.errors[fieldStr]) {
        delete this.service.errors[fieldStr];
      }

      this.$emit('input', event);
    },
  },
};
</script>

<style>
.helpText {
  color: #808080;
  font-size: 12px;
}
.autocomplete-search-icon > input[type='text'] {
  padding-left: 35px;
}
</style>
