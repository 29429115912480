export const ru = {
    // Collections
    'January': 'Январь',
    'February': 'Февраль',
    'March': 'Март',
    'April': 'Апрель',
    'May': 'Май',
    'June': 'Июнь',
    'July': 'Июль',
    'August': 'Август',
    'September': 'Сентябрь',
    'October': 'Октябрь',
    'November': 'Ноябрь',
    'December': 'Декабрь',

    'Jan': 'Янв',
    'Feb': 'Фев',
    'Mar': 'Мар',
    'Apr': 'Апр',
    'May': 'Май',
    'Jun': 'Июн',
    'Jul': 'Июл',
    'Aug': 'Авг',
    'Sep': 'Сен',
    'Oct': 'Окт',
    'Nov': 'Ноя',
    'Dec': 'Дек',

    'of January': 'Января',
    'of February': 'Февраля',
    'of March': 'Марта',
    'of April': 'Апреля',
    'of May': 'Мая',
    'of June': 'Июня',
    'of July': 'Июля',
    'of August': 'Августа',
    'of September': 'Сентября',
    'of October': 'Октября',
    'of November': 'Ноября',
    'of December': 'Декабря',

    'Mo': 'Пн',
    'Tu': 'Вт',
    'We': 'Ср',
    'Th': 'Чт',
    'Fr': 'Пт',
    'Sa': 'Сб',
    'Su': 'Вс',

    'No': 'Нет',
    'Yes': 'Да',

    // GridView component
    'No data': 'Нет данных',
    'of': 'из',
    'Showing': 'Показано',

    // GridViewCell component
    'hide': 'скрыть',
    'more': 'ещё',

    // SpeedDialBtn component
    'Are you sure you want to proceed?': 'Вы уверены, что хотите продолжить?',
    'Confirmation': 'Подтверждение',
    'Create': 'Создание',
    'Delete': 'Удалить',
    'Edit': 'Редактировать',
    'Export': 'Экспорт',
    'Import': 'Импорт',
    'Save': 'Сохранить',
    'Show': 'Просмотр',

    // FileField component
    'File downloaded': 'Файл загружен',

    // toast plugin
    'Attention': 'Внимание',
    'Error': 'Ошибка',
    'Success': 'Успешно',

    // Other
    '#Contract': '#Сделка',

    'Access': 'Доступ',
    'Access denied': 'Доступ закрыт',
    'Account': 'Аккаунт',
    'Accounts': 'Аккаунты',
    'Account ID': 'ID Аккаунта',
    'Accountant position': 'Должность бухгалтера',
    'Action': 'Действие',
    'Action settings': 'Настройки действия',
    'Active': 'Активный',
    'Activate': 'Активировать',
    'Activity': 'Деятельность',
    'Ad ID': 'Идентификатор объявления',
    'ad id on the board': 'id объявления на доске',
    'Add': 'Добавить',
    'Add by link from the site': 'Добавить по ссылке с сайта',
    'Add check list': 'Добавить чек лист',
    'Add funnel': 'Добавить воронку',
    'Add group': 'Добавить группу',
    'Add tag': 'Добавить метку',
    'Add task': 'Добавить задачу',
    'Add element': 'Добавить элемент',
    'Add. email': 'Доп. email',
    'Add a PBX user': 'Добавить пользователя АТС',
    'Add. telephone': 'Доп. телефон',
    'Address': 'Адрес',
    'Address legal': 'Юридический адрес',
    'Additional fields': 'Дополнительные поля',
    'Advertising link': 'Рекламная ссылка',
    'Affiliate program': 'Партнерская программа',
    'Albato': 'Альбато',
    'All': 'Все',
    'All active': 'Активные',
    'All inactive': 'Неактивные',
    'Amount': 'Сумма',
    'and': 'и',
    'App': 'Приложение',
    'Apps': 'Приложения',
    'Apply to all users': 'Применить для всех пользователей',
    'Area': 'Площадь',
    'Are kids allowed?': 'Можно с детьми?',
    'Are parties allowed?': 'Разрешены ли вечеринки и мероприятия?',
    'Are pets allowed?': 'Можно с питомцами?',
    'Archive': 'Архив',
    'Article': 'Статья',
    'Articles': 'Статьи',
    'At work': 'В работе',
    'Attach to': 'Прикрепить к',
    'Attach file': 'Прикрепить файл',
    'Attached': 'Прикреплен',
    'Author': 'Автор',
    'Auction': 'Аукцион',
    'Authorization error': 'Ошибка авторизации',

    'Bargain type': 'Аренда/Продажа',
    'Basis': 'Основание',
    'Bank': 'Банк',
    'Bank account': 'Банковский счет',
    'Bank accounts': 'Банковские счета',
    'Balconies count': 'Количество балконов',
    'Begin': 'Начать',
    'BIC': 'БИК',
    'Billing information': 'Данные для выставления счета',
    'board id': 'id доски',
    'Bold': 'Жирный',
    'Budget': 'Бюджет',
    'Building type': 'Тип здания',
    'Building class': 'Класс здания',
    'Built year': 'Год постройки',
    'Business direction': 'Направление бизнеса',
    'Bulleted list': 'Маркированный список',
    'Button name': 'Название кнопки',

    'Cadastral numbers': 'Кадастровые номера',
    'Cadastral number': 'Кадастровый номер',
    'Cadastral number of the area': 'Кадастровый номер участка',
    'Call history': 'История звонков',
    'Call ID': 'ID звонка',
    'Call recording': 'Запись звонка',
    'Call start date': 'Дата начала звонка',
    'Calls': 'Звонки',
    'Cancel': 'Отмена',
    'Canceled': 'Отменен',
    'Cards': 'Карточки',
    'Catalog': 'Каталог',
    'Catalog colors': 'Цвета каталога',
    'Catalog groups': 'Группы каталога',
    'Catalog packs': 'Упаковки каталога',
    'Category': 'Категория',
    'Categories': 'Категории',
    'Channel': 'Канал',
    'Channel ID': 'ID Канала',
    'Channels': 'Каналы',
    'Channel type': 'Тип канала',
    'Channel name': 'Название канала',
    'Changed': 'Изменен',
    'Chat': 'Чат',
    'Chat-bot': 'Чат-бот',
    'Chat-bots': 'Чат-боты',
    'Chats': 'Чаты',
    'Cash': 'Наличные',
    'Cash Flow': 'ДДС',
    'Cash Flow Statement': 'Отчет о движении денежных средств',
    'Cashless': 'Безналичные',
    'Cashbox': 'Касса',
    'Ceiling height': 'Высота потолков',
    'Check': 'Проверка',
    'Check list': 'Чек лист',
    'Checking account': 'Расчетный счет',
    'Choose or create': 'Выберите или создайте',
    'Choose an event to trigger your process': 'Выберите событие для запуска вашего процесса',
    'Choose an action you want to perform': 'Выберите действие, которое вы хотите выполнить',
    'Cities': 'Города',
    'City': 'Город',
    'City, street, house': 'Город, улица, дом',
    'Clear': 'Очистить',
    'Clear formatting': 'Очистить форматирование',
    'client': 'Клиенты', // for Meilisearch
    'Client': 'Клиент',
    'Client commission cost': 'Сумма комиссии взятая с клиента',
    'Client city': 'Город клиента',
    'Client email': 'Email клиента',
    'Client name': 'Имя клиента',
    'Client phone': 'Телефон клиента',
    'Client removed': 'Клиент удален',
    'Clients': 'Клиенты',
    'Close': 'Закрыть',
    'Closed deals': 'Закрытые сделки',
    'Code': 'Код',
    'Color': 'Цвет',
    'Come from': 'Откуда',
    'Comment': 'Комментарий',
    'Comment on the invoice': 'Комментарий к счету',
    'Commerce and garages': 'Коммерция и гаражи',
    'Completed': 'Завершенные',
    'Contact': 'Контакт',
    'Contact support': 'Обратиться в тех.поддержку',
    'Contact your administrator': 'Обратитесь к администратору',
    'Contacts': 'Контакты',
    'Contract': 'Сделка',
    'Contract ID': 'ID Сделки',
    'Contract removed': 'Сделка удалена',
    'Contracts': 'Сделки',
    'Contracts of this client': 'Сделки этого клиента',
    'Conversion': 'Конверсия',
    'Convert to deal': 'Конверсия в сделку',
    'Campaign content': 'Содержание кампании',
    'Campaign ID': 'Идентификатор кампании',
    'Campaign name': 'Название кампании',
    'Company': 'Компания',
    'Company executor': 'Компании исполнители',
    'Companies clients': 'Компании клиенты',
    'Companies': 'Компании',
    'Comments': 'Комментарии',
    'Commission cost': 'Сумма комиссии',
    'Commission amount': 'Размер комиссии',
    'Copy': 'Скопировать',
    'copied': 'скопирован',
    'Correspondent account': 'Корреспондентский счет',
    'Cost': 'Стоимость',
    'Cost min': 'Минимальная цена',
    'Cost max': 'Максимальная цена',
    'Country': 'Страна',
    'Cover': 'Обложка',
    'Covers': 'Обложки',
    'Got tasks for today': 'Есть задачи на сегодня',
    'Create': 'Создать',
    'Created': 'Создано',
    'Created an object': 'Создал объект',
    'Create funnel': 'Создание воронки',
    'Create new': 'Создать нового',
    'Create a new': 'Создать новую',
    'Create a deal': 'Создать сделку',
    'Create a lead': 'Создать лид',
    'Create a task': 'Создать задачу',
    'Create a client': 'Создать клиента',
    'Create a copy': 'Создать копию',
    'Create a user': 'Создать пользователя',
    'Currency': 'Валюта',

    'Date': 'Дата',
    'Date and time': 'Дата и время',
    'Date due': 'Дата возврата',
    'Date of Birth': 'Дата рождения',
    'Date of creation': 'Дата создания',
    'Date of payment': 'Дата оплаты',
    'Date invoice': 'Дата счета',
    'Data related to this transaction will be deleted': 'Данные, связанные с этой сделкой будут удалены',
    'Deadline': 'Крайний срок',
    'Deadline not specified': 'Срок не указан',
    'Deal amount': 'Сумма сделки',
    'Deal type': 'Тип сделки',
    'Deals without tasks': 'Сделки без задач',
    'Deactivate': 'Деактивировать',
    'Decoration of room': 'Отделка помещения',
    'Delete checklist?': 'Удалить чек лист?',
    'Delete deal': 'Удалить сделку',
    'Deleted stage?': 'Удалить этап?',
    'Demonstration': 'Показ',
    'Deposit': 'Залог',
    'Description': 'Описание',
    'Desktop': 'Рабочий стол',
    'Details': 'Характеристики',
    'Developer': 'Разработчик',
    'Didn\'t like the price': 'Не устроила цена',
    'Disable': 'Отключить',
    'Disable integration': 'Отключить интеграцию',
    'Discount cost': 'Сумма скидки',
    'Districts': 'Районы',
    'Do you want to delete this message?': 'Вы хотите удалить это сообщение?',
    'Don\'t have an account yet?': 'У вас еще нет аккаунта?',
    'Done': 'Выполнено',
    'Download': 'Загрузить',
    'Duplicate': 'Дубль',
    'Duplicates': 'Дубли',
    'Duration': 'Продолжительность',
    'Duration with beeps': 'Продолжительность с гудками',

    'E-mail': 'E-mail',
    'Edit funnel': 'Редактирование воронки',
    'Edit tag': 'Редактирование метки',
    'Editing': 'Редактирование',
    'Email': 'Электронная почта',
    'Employee': 'Сотрудник',
    'Enter a search value': 'Введите значение для поиска',
    'Enter the name of the stage': 'Введите наименование этапа',
    'Enter the client\'s Name, Phone Number, or Email address': 'Введите Имя, Телефон или Email клиента',
    'Entrance': 'Вход',
    'Event': 'Событие',
    'Event Settings': 'Настройки события',
    'Events': 'Мероприятия',
    'Executors': 'Исполнители',
    'Exclude': 'Исключить',
    'Expend': 'Списание',
    'Expenses': 'Списания',
    'Exportfile': 'Фиды для загрузки на сайты',
    'External': 'Внешний',

    'Female': 'Женский',
    'Field': 'Поле',
    'file': 'файл',
    'Files': 'Файлы',
    'Filter': 'Фильтр',
    'Finance': 'Финансы',
    'Finance category': 'Статья',
    'Finance Categories': 'Статьи операций',
    'First': 'Первый',
    'Fixed': 'Фиксировано',
    'From': 'От',
    'from': 'от',
    'Freight elevator': 'Грузовой лифт',
    'Flat': 'Квартира',
    'Flats and rooms': 'Квартиры и комнаты',
    'Floor': 'Этаж',
    'Floors': 'Этажность',
    'Forgot your password?': 'Забыли пароль?',
    'Format': 'Формат',
    'Full description': 'Полное описание',
    'Full name': 'ФИО',
    'Full name of the Head': 'ФИО руководителя',
    'Full name of the accountant': 'ФИО бухгалтера',
    'Funnel': 'Воронка',
    'Funnels': 'Воронки',
    'Funnel stage': 'Этап воронки',
    'Features': 'Содержит',

    'Garage type': 'Тип гаража',
    'Gender': 'Пол',
    'General': 'Общая',
    'Go to deal': 'Перейти в сделку',
    'Got photo?': 'Есть фото',
    'Group by': 'Группировать по',

    'Have Your legals?': 'Есть отчётные документы?',
    'Has no tasks': 'Нет задач',
    'Help': 'Помощь',
    'Hypothec allowed': 'Можно в ипотеку',
    'House': 'Дом',
    'Houses and land': "Дома и участки",

    'Id': 'Id',
    'ID from the payment system': 'ID с платежной системы',
    'Images': 'Изображения',
    'In Latin': 'На Латинице',
    'In work': 'В работе',
    'In the day': 'В сутки',
    'In the selection': 'В подборку',
    'Inactive': 'Неактивный',
    'Included in price': 'Включено в цену',
    'Incoming': 'Входящий',
    'Incomings': 'Входящие',
    'Income': 'Поступление',
    'Incomes': 'Поступления',
    'Incomes (Plan)': 'Поступления (План)',
    'Info': 'Инфо',
    'Information': 'Информация',
    'Infrastructure': 'Инфраструктура',
    'Initial balance': 'Начальный остаток',
    'Initial balance data': 'Дата начального остатка',
    'Invalid client config': 'Неверная конфигурация клиента',
    'Invalid login or password': 'Неверный логин или пароль',
    'Internal number phone': 'Внутренний номер',
    'INN': 'ИНН',
    'Integrations': 'Интеграции',
    'Install': 'Установить',
    'Installation': 'Установка',
    'Installed': 'Установлено',
    'Installed ': 'Установленные',
    'Instructions': 'Инструкции',
    'IP': 'IP Адрес',
    'Italic': 'Курсив',

    'Group': 'Группа',

    'Kanban': 'Канбан',
    'Key': 'Ключ',
    'Key phrase': 'Ключевая фраза',
    'Kitchen': 'Кухня',
    'Kitchen area': 'Площадь кухни',
    'KPP': 'КПП',

    'Label': 'Наименование',
    'Name of goods or services': 'Наименование товаров или услуг',
    'Land area': 'Площадь земли',
    'Land category': 'Категория земель',
    'Last comment': 'Последний комментарий',
    'Last change': 'Последнее изменение',
    'Layout': 'Расположение',
    'Lead': 'Лид',
    'Lead ID': 'ID Лида',
    'Leads': 'Лиды',
    'Leads of this client': 'Лиды этого клиента',
    'Living area': 'Площадь жилая',
    'List': 'Список',
    'Lists': 'Списки',
    'Link to the invoice': 'Ссылка на счет',
    'Loading': 'Загрузка',
    'Log out': 'Выход',
    'Login': 'Логин',
    'Loggias count': 'Количество лоджий',
    'Long-term': 'Долгосрочный',
    'Loss': 'Убыток',
    'Lost': 'Проиграно',

    'Main': 'Основное',
    'Male': 'Мужской',
    'Measure': 'Мера',
    'Message not found': 'Cообщение не найдено',
    'Message': 'Сообщение',
    'Messenger': 'Мессенджер',
    'Method of transportation': 'Способ передвижения',
    'Minutes to the metro': 'Минут до метро',
    'Months': 'Месяцев',
    'Money transfers': 'Переводы',
    'More detailed': 'Подробнее',
    'My companies': 'Мои компании',
    'My': 'Мои',

    'NA': 'НД',
    'Name': 'Имя',
    'Nearest metro': 'Ближайшее метро',
    'Net cash flows': 'Сальдо',
    'Network Error': 'Ошибка сети',
    'New': 'Новые',
    'New building': 'Новостройка',
    'New development': 'Жилой комплекс',
    'New contacts': 'Новых контактов',
    'New contracts': 'Новых сделок',
    'New leads': 'Новых лидов',
    'No comments': 'Нет комментариев',
    'No calls': 'Нет звонков',
    'No tasks': 'Нет задач',
    'No tasks for this deal': 'Нет задач по этой сделке',
    'No tasks for this lead': 'Нет задач по этому лиду',
    'No payments': 'Нет платежей',
    'Novofon': 'Новофон',
    'Not done': 'Не выполнено',
    'Not found': 'Не найдено',
    'Not answer': 'Не дозвонились',
    'Not paid': 'Не оплачен',
    'Not specified': 'Не указан',
    'Notification settings': 'Настройки уведомлений',
    'Notifications': 'Уведомления',
    'Number invoice': 'Номер счета',
    'Number of rooms': 'Кол-во комнат',
    'Number of rooms in flat': 'Кол-во комнат в квартире',
    'Number of rooms for rent': 'Кол-во комнат в аренду',
    'Numbered list': 'Нумерованный список',

    'Object': 'Объект',
    'Object count': 'Количество Объектов',
    'Office': 'Офис',
    'Offices': 'Офисы',
    'OGRN': 'ОГРН',
    'One-time': 'Разовый',
    'Option': 'Опция',
    'Options': 'Опции',
    'Org executor': 'Компания исполнитель',
    'Organization': 'Организация',
    'Organizations': 'Организации',
    'Overdue tasks': 'Просроченные задачи',
    'Owner user': 'Ответственный',
    'Owner': 'Собственник',
    'Outgoing': 'Иcходящий',
    'Outgoings': 'Иcходящие',

    'Pack': 'Упаковка',
    'Paid': 'Оплачен',
    'Paid for': 'Оплачено',
    'Params': 'Параметры',
    'Parking type': 'Тип парковки',
    'Parking space type': 'Тип машиноместа',
    'Pay before': 'Оплатить до',
    'Payout': 'Расход',
    'Payer': 'Плательщик',
    'Payees bank': 'Банк получателя',
    'Password': 'Пароль',
    'Passenger elevator': 'Пассажирский лифт',
    'Patronymic': 'Отчество',
    'Pay statistics': 'Статистика финансов',
    'Pay for subscription': 'Оплатить подписку',
    'Payment': 'Оплата',
    'Payment account': 'Счет',
    'Payment system': 'Система оплаты',
    'Payments': 'Платежи',
    'PBX': 'АТС',
    'PBX Users': 'Пользователи АТС',
    'Per month': 'В месяц',
    'Per period': 'За период',
    'Permission': 'Разрешение',
    'Permissions': 'Разрешения',
    'Period': 'Период',
    'Phone': 'Телефон',
    'Photo': 'Фото',
    'Photo and files': 'Фото и файлы',
    'Poor quality': 'Некачественные',
    'Position of the head': 'Должность руководителя',
    'Positions': 'Позиции',
    'Purpose of payment': 'Назначение платежа',
    'Price': 'Цена',
    'Price and terms of the transaction': 'Цена и условия сделки',
    'Prepayment': 'Предоплата',
    'Process': 'Процесс',
    'Processes': 'Процессы',
    'Producer': 'Постановщик',
    'Product or service': 'Товар или услуга',
    'Profile': 'Профиль',
    'Profit': 'Прибыль',
    'Profit/Loss': 'Прибыль/Убыток',
    'Project': 'Проект',
    'Project Manager': 'Менеджер проекта',
    'Project name': 'Название проекта',
    'Project type': 'Тип проекта',
    'Property type': 'Тип недвижимости',

    'Quality': 'Качественные',
    'Quantity': 'Количество',
    'Q': 'Кол-во',
    'Quick add': 'Быстрое добавление',

    'Reason for deletion?': 'Причина удаления?',
    'Real estate objects': 'Объекты недвижимости',
    'Receipt': 'Приход',
    'Recipient': 'Получатель',
    'Receipts': 'Приходы',
    'Referrer': 'Реферер',
    'Region': 'Регион',
    'Register': 'Зарегистрироваться',
    'Register to install': 'Для установки зарегистрируйтесь в',
    'Regular expression': 'Регулярное выражение',
    'Rejection reason': 'Причина отказа',
    'Relation': 'Связь',
    'Relations': 'Связи',
    'Required': 'Обязательный',
    'removed': 'удален',
    'Rent': 'Аренда',
    'Rent flat': 'Аренда квартиры',
    'Rent house': 'Аренда дома',
    'Rent space': 'Аренда нежилой недвижимости',
    'Rent flats': 'Аренда квартир',
    'Rent houses': 'Аренда домов',
    'Rent spaces': 'Аренда нежилой недвижимости',
    'Rental type': 'Тип аренды',
    'Repair': 'Ремонт',
    'Reply': 'Ответ',
    'Requests': 'Заявок',
    'Reserve date': 'Дата резерва',
    'Responsible': 'Ответственный',
    'Responsible user': 'Ответственный пользователь',
    'Residential': 'Жилая',
    'Return to new': 'Вернуть в новые',
    'Role': 'Роль',
    'Roles': 'Роли',
    'Released': 'Освободится',
    'Room area': 'Площадь комнаты',

    'Sale': 'Продажа',
    'Safe demonstration': 'Онлайн показ',
    'Sale method': 'Способ продажи',
    'Sale of a piece': 'Продажа доли',
    'Sale options': 'Опции продажи',
    'Sale flat': 'Продажа квартиры',
    'Sale house': 'Продажа дома',
    'Sale space': 'Продажа нежилой недвижимости',
    'Sale flats': 'Продажа квартир',
    'Sale houses': 'Продажа домов',
    'Sale spaces': 'Продажа нежилой недвижимости',
    'Search': 'Поиск',
    'Search by Lead, Contract, Client': 'Поиск по Лидам, Сделкам и Клиентам',
    'Search message': 'Поиск сообщения',
    'Section': 'Раздел',
    'Secured': 'Охрана',
    'Send': 'Отправить',
    'Sending': 'Рассылки',
    'Select': 'Выбрать',
    'Select all': 'Выбрать все',
    'Select from database': 'Выбрать из базы',
    'Select the nearest metro stations': 'Выберите ближайшие станции метро',
    'Select the event that will start the process.': 'Выберите событие, которое запустит процесс.',
    'Select the action to be performed.': 'Выберите действие, которое будет выполнено.',
    'Service': 'Услуга',
    'Services': 'Услуги',
    'Settings': 'Настройки',
    'Setting': 'Настройка',
    'Settlement': 'Поселение',
    'Short description': 'Краткое описание',
    'Show': 'Показывают',
    'Show closed stages': 'Показать закрытые этапы',
    'Show date': 'Дата показа',
    'Sign ceo': 'Подпись руководителя',
    'Sign cfo': 'Подпись бухгалтера',
    'Sign in': 'Вход',
    'Sign In': 'Войти',
    'Site ID': 'Идентификатор площадки',
    'Sku': 'Артикул',
    'Source': 'Источник',
    'Sources': 'Источники',
    'Source statistics': 'Статистика источников',
    'Specifications': 'Характеристики',
    'Specify the Contract ID to search for leads related to the specified contract': 'Укажите ID Сделки для поиска лидов связанных с указанной сделкой',
    'Stage': 'Этап',
    'Statistics': 'Статистика',
    'Status': 'Статус',
    'Stamp': 'Печать',
    'Street': 'Улица',
    'Structure': 'Структура',
    'Subscribe': 'Подписаться',
    'Subscribed': 'Подписан',
    'Subscribers': 'Подписчики',
    'Subscribe to push': 'Подключить push-уведомления',
    'Supplement': 'Дополнить',
    'Surname': 'Фамилия',
    'Successful bid': 'Успешных заявок',
    'Successfully completed': 'Успешно завершено',
    'Sum': 'Сумма',

    'Table': 'Таблица',
    'Tag': 'Метка',
    'Tags': 'Метки',
    'Tags of statistical systems': 'Метки систем статистики',
    'Talk time': 'Время разговора',
    'Task': 'Задача',
    'Tasks create': 'Задач создано',
    'Task description': 'Описание задачи',
    'Task type': 'Тип задачи',
    'Tasks': 'Задачи',
    'Tariff': 'Тариф',
    'Tariffs': 'Тарифы',
    'To call': 'Позвонить',
    'To archive': 'В архив',
    'To actual': 'В актуальные',
    'To plug': 'Подключить',
    'To': 'До',
    'to': 'до',
    'To reply': 'Ответить',
    'to whom': 'кому',
    'To whom': 'Кому',
    'To write a comment...': 'Написать комментарий...',
    'Today': 'Сегодня',
    'Telephony': 'Телефония',
    'Templates': 'Шаблоны',
    'TemplateId ID': 'ID Шаблона',
    'Total': 'Итого',
    'Total area': 'Площадь общая',
    'The attach': 'Прикрепить',
    'The deal has no goals': 'У сделки нет задач',
    'The lead has no goals': 'У лида нет задач',
    'The funnel cannot be deleted while there are deals in it': 'Воронку нельзя удалить, пока в ней есть сделки',
    'The link in the button': 'Ссылка в кнопке',
    'The phone from which the call was made': 'Телефон с которого позвонили',
    'The phone that was called': 'Телефон на которого позвонили',
    'The process is activated': 'Процесс активирован',
    'The process has been deactivated': 'Процесс деактивирован',
    'The secret key': 'Секретный ключ',
    'the url of the ad on the board': 'url объявления на доске',
    'There are no active users. Add a user to start a conversation.': 'Нет активных пользователей. Добавьте пользователя, чтобы начать общение.',
    'There are overdue tasks': 'Есть просроченные задачи',
    'There are tasks for the future': 'Есть задачи на будущее',
    'Title': 'Наименование',
    'Traffic channel': 'Канал трафика',
    'Traffic source': 'Источник трафика',
    'Transaction count': 'Количество транзакций',
    'Trash': 'Удалить',
    'Trigger': 'Триггер',
    'Trigger settings': 'Настройки триггера',
    'Type': 'Тип',
    'Type of house': 'Тип дома',

    'Update': 'Редактирование',
    'Update date': 'Дата обновления',
    'Updated': 'Обновлено',
    'Unit': 'Офис',
    'Unit of measurement': 'Единица измерения',
    'U.measure': 'Ед.изм.',
    'Unsubscribed': 'Отписан',
    'Unsucess': 'Неуспешно',
    'Unsuccessfully completed': 'НЕуспешно завершено',
    'Underline': 'Подчеркнутый',
    'Untie': 'Отвязать',
    'User': 'Пользователь',
    'Users': 'Пользователи',
    'Uses': 'Применение',
    'UTM tags': 'UTM-метки',

    'View from the windows': 'Вид из окон',
    'Video': 'Видео',
    'Validate error': 'Ошибка валидации',
    'Value': 'Значение',
    'VAT': 'НДС',
    'VAT rate': 'Ставка НДС',
    'VAT amount': 'Сумма НДС',

    'Who called': 'Кто звонил',
    'Who created': 'Кто создал',
    'Where did you call': 'Куда звонили',
    'Went to competitors': 'Ушли к конкурентам',
    'Win': 'Выиграно',
    'With duplicates': 'С дублями',
    'Without a reason': 'Без причины',
    'Without VAT': 'Без НДС',
    'Write a message...': 'Напишите сообщение...',

    'You can smoke?': 'Можно курить?',
    'You can pets?': 'Можно с питомцами?',
    'You can children?': 'Можно с детьми?',

    'Zadarma': 'Новофон',

    '% conversion': '% конверсии',
    '% of total': '% от общего количества',
    '% of total quality leads': '% от общего количества качественных лидов',
}
