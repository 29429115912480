// import { createRouter } from './default-router';
import { createRouter } from './modal-router';

const routes = [
  // home
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },

  // auth
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
    meta: { layout: 'start', auth: false },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue')
  },

  // Account
  {
    path: '/account',
    name: 'Account',
    component: () => import('../modules/account/views/account/Index.vue'),
  },
  {
    path: '/account/update/:id',
    name: 'AccountUpdate',
    component: () => import('../modules/account/views/account/Update.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },

  // App
  {
    path: '/app/store',
    name: 'AppStore',
    component: () => import('../modules/app/views/store/Index.vue'),
  },
  {
    path: '/app',
    name: 'App',
    component: () => import('../modules/app/views/app/Index.vue'),
  },
  {
    path: '/app/create/:identifier',
    name: 'AppCreate',
    component: () => import('../modules/app/views/app/Create.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },
  {
    path: '/app/update/:id',
    name: 'AppUpdate',
    component: () => import('../modules/app/views/app/Update.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },
  //{
  //  path: '/app/zadarma/test',
  //  name: 'AppStore',
  //  component: () => import('../modules/app/views/zadarma/Test.vue'),
  //},

  // Article
  {
    path: '/article',
    name: 'Article',
    component: () => import('../modules/article/views/article/Index.vue'),
  },
  {
    path: '/article/create',
    name: 'ArticleCreate',
    component: () => import('../modules/article/views/article/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/article/update/:id',
    name: 'ArticleUpdate',
    component: () => import('../modules/article/views/article/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/article/category',
    name: 'ArticleCategory',
    component: () => import('../modules/article/views/category/Index.vue'),
  },
  {
    path: '/article/category/create',
    name: 'ArticleCategoryCreate',
    component: () => import('../modules/article/views/category/Create.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },
  {
    path: '/article/category/update/:id',
    name: 'ArticleCategoryUpdate',
    component: () => import('../modules/article/views/category/Update.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },

  // Catalog
  {
    path: '/catalog',
    name: 'Catalog',
    component: () => import('../modules/catalog/views/catalog/Index.vue'),
  },
  {
    path: '/catalog/:id',
    name: 'CatalogShow',
    component: () => import('../modules/catalog/views/catalog/Show.vue'),
    meta: { isModal: true },
  },
  {
    path: '/catalog/update/:id',
    name: 'CatalogUpdate',
    component: () => import('../modules/catalog/views/catalog/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/catalog/create',
    name: 'CatalogCreate',
    component: () => import('../modules/catalog/views/catalog/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/catalog/import',
    name: 'CatalogImport',
    component: () => import('../modules/catalog/views/catalog/Import.vue'),
    meta: { isModal: true },
  },
  {
    path: '/catalog/group',
    name: 'CatalogGroup',
    component: () => import('../modules/catalog/views/group/Index.vue'),
  },
  {
    path: '/catalog/group/update/:id',
    name: 'CatalogGroupUpdate',
    component: () => import('../modules/catalog/views/group/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/catalog/group/create',
    name: 'CatalogGroupCreate',
    component: () => import('../modules/catalog/views/group/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/catalog/pack',
    name: 'CatalogPack',
    component: () => import('../modules/catalog/views/pack/Index.vue'),
  },
  {
    path: '/catalog/pack/update/:id',
    name: 'CatalogPackUpdate',
    component: () => import('../modules/catalog/views/pack/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/catalog/pack/create',
    name: 'CatalogPackCreate',
    component: () => import('../modules/catalog/views/pack/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/catalog/color',
    name: 'CatalogColor',
    component: () => import('../modules/catalog/views/color/Index.vue'),
  },
  {
    path: '/catalog/color/update/:id',
    name: 'CatalogColorUpdate',
    component: () => import('../modules/catalog/views/color/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/catalog/color/create',
    name: 'CatalogColorCreate',
    component: () => import('../modules/catalog/views/color/Create.vue'),
    meta: { isModal: true },
  },

  // Chat
  {
    path: '/chat/message',
    name: 'ChatMessage',
    component: () => import('../modules/chat/views/message/Index.vue'),
  },

  // ChatClient
  {
    path: '/chat/client',
    name: 'ChatClient',
    component: () => import('../modules/chat/views/client/Index.vue'),
  },
  {
    path: '/chat/client/update/:id',
    name: 'ChatClientUpdate',
    component: () => import('../modules/chat/views/client/Update.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },
  {
    path: '/chat/client-sending',
    name: 'ChatClientSending',
    component: () => import('../modules/chat/views/clientSending/Index.vue'),
  },
  {
    path: '/chat/client-sending/create',
    name: 'ChatClientSendingCreate',
    component: () => import('../modules/chat/views/clientSending/Create.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },
  {
    path: '/chat/client-sending/update/:id',
    name: 'ChatClientSendingUpdate',
    component: () => import('../modules/chat/views/clientSending/Update.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },
  {
    path: '/chat/client-channel',
    name: 'ChatClientChannel',
    component: () => import('../modules/chat/views/clientChannel/Index.vue'),
  },
  {
    path: '/chat/client-channel/update/:id',
    name: 'ChatClientChannelUpdate',
    component: () => import('../modules/chat/views/clientChannel/Update.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },

  // Client
  {
    path: '/client',
    name: 'Client',
    component: () => import('../modules/client/views/client/Index.vue'),
  },
  {
    path: '/client/:id',
    name: 'ClientShow',
    component: () => import('../modules/client/views/client/Show.vue'),
    meta: { isModal: true },
  },
  {
    path: '/client/update/:id',
    name: 'ClientUpdate',
    component: () => import('../modules/client/views/client/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/client/create',
    name: 'ClientCreate',
    component: () => import('../modules/client/views/client/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/client/file',
    name: 'ClientFile',
    component: () => import('../modules/client/views/file/Index.vue'),
  },
  {
    path: '/client/file/update/:id',
    name: 'ClientFileUpdate',
    component: () => import('../modules/client/views/file/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/client/import',
    name: 'ClientImport',
    component: () => import('../modules/client/views/client/Import.vue'),
    meta: { isModal: true },
  },

  // Contract
  {
    path: '/contract',
    name: 'Contract',
    component: () => import('../modules/contract/views/contract/Index.vue'),
  },
  {
    path: '/contract/kanban',
    name: 'ContractKanban',
    component: () => import('../modules/contract/views/kanban/Index.vue'),
  },
  {
    path: '/contract/update/:id',
    name: 'ContractUpdate',
    component: () => import('../modules/contract/views/contract/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/contract/create',
    name: 'ContractCreate',
    component: () => import('../modules/contract/views/contract/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },

  // ContractCatalog
  {
    path: '/contract-catalog',
    name: 'ContractCatalog',
    component: () => import('../modules/contractCatalog/views/contractCatalog/Index.vue'),
  },

  // Exportfile
  {
    path: '/exportfile',
    name: 'Exportfile',
    component: () => import('../modules/exportfile/views/Index.vue'),
  },
  {
    path: '/exportfile/update/:id',
    name: 'ExportfileUpdate',
    component: () => import('../modules/exportfile/views/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/exportfile/create',
    name: 'exportfileCreate',
    component: () => import('../modules/exportfile/views/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },

  // Funnel
  {
    path: '/funnel',
    name: 'Funnel',
    component: () => import('../modules/funnel/views/funnel/Index.vue'),
  },
  {
    path: '/funnel/create',
    name: 'FunnelCreate',
    component: () => import('../modules/funnel/views/funnel/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/funnel/update/:id',
    name: 'FunnelUpdate',
    component: () => import('../modules/funnel/views/funnel/Update.vue'),
    meta: { isModal: true },
  },

  // FunnelStage
  {
    path: '/funnel/stage/update/:id',
    name: 'FunnelStageUpdate',
    component: () => import('../modules/funnel/views/stage/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/funnel-stage/create',
    name: 'FunnelStageCreate',
    component: () => import('../modules/funnel/views/stage/Create.vue'),
    meta: { isModal: true },
  },

  // Lead
  {
    path: '/lead',
    name: 'Lead',
    component: () => import('../modules/lead/views/lead/Index.vue'),
  },
  {
    path: '/lead/card',
    name: 'LeadCard',
    component: () => import('../modules/lead/views/lead/Card.vue'),
  },
  {
    path: '/lead/:id',
    name: 'LeadShow',
    component: () => import('../modules/lead/views/lead/Show.vue'),
    meta: { isModal: true },
  },
  {
    path: '/lead/update/:id',
    name: 'LeadUpdate',
    component: () => import('../modules/lead/views/lead/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/lead/create',
    name: 'LeadCreate',
    component: () => import('../modules/lead/views/lead/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/lead/import',
    name: 'LeadImport',
    component: () => import('../modules/lead/views/lead/Import.vue'),
    meta: { isModal: true },
  },

  // LocationCity
  {
    path: '/location/city',
    name: 'LocationCity',
    component: () => import('../modules/location/views/city/Index.vue'),
  },
  {
    path: '/location/city/update/:id',
    name: 'LocationCityUpdate',
    component: () => import('../modules/location/views/city/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/location/city/create',
    name: 'LocationCityCreate',
    component: () => import('../modules/location/views/city/Create.vue'),
    meta: { isModal: true },
  },

  // LocationDistrict
  {
    path: '/location/district',
    name: 'LocationDistrict',
    component: () => import('../modules/location/views/district/Index.vue'),
  },
  {
    path: '/location/district/update/:id',
    name: 'LocationDistrictUpdate',
    component: () => import('../modules/location/views/district/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/location/district/create',
    name: 'LocationDistrictCreate',
    component: () => import('../modules/location/views/district/Create.vue'),
    meta: { isModal: true },
  },

  // LocationUnit
  {
    path: '/location/unit',
    name: 'LocationUnit',
    component: () => import('../modules/location/views/unit/Index.vue'),
  },
  {
    path: '/location/unit/:id',
    name: 'LocationUnitShow',
    component: () => import('../modules/location/views/unit/Show.vue'),
    meta: { isModal: true },
  },
  {
    path: '/location/unit/update/:id',
    name: 'LocationUnitUpdate',
    component: () => import('../modules/location/views/unit/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/location/unit/create',
    name: 'LocationUnitCreate',
    component: () => import('../modules/location/views/unit/Create.vue'),
    meta: { isModal: true },
  },

  // Organization
  {
    path: '/org',
    name: 'Org',
    component: () => import('../modules/org/views/org/Index.vue'),
  },
  {
    path: '/org/update/:id',
    name: 'OrgUpdate',
    component: () => import('../modules/org/views/org/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/org/create',
    name: 'OrgCreate',
    component: () => import('../modules/org/views/org/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/org/account/create',
    name: 'OrgAccountCreate',
    component: () => import('../modules/org/views/orgAccount/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/org/account/update/:id',
    name: 'OrgAccountUpdate',
    component: () => import('../modules/org/views/orgAccount/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/org/client',
    name: 'OrgClient',
    component: () => import('../modules/org/views/client/Index.vue'),
  },
  {
    path: '/org/client/update/:id',
    name: 'OrgClientUpdate',
    component: () => import('../modules/org/views/client/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/org/client/create',
    name: 'OrgClientCreate',
    component: () => import('../modules/org/views/client/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/org/client-account/create',
    name: 'OrgClientAccountCreate',
    component: () => import('../modules/org/views/clientAccount/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/org/client-account/update/:id',
    name: 'OrgClientAccountUpdate',
    component: () => import('../modules/org/views/clientAccount/Update.vue'),
    meta: { isModal: true },
  },
  // executor
  {
    path: '/org/executor',
    name: 'OrgExecutor',
    component: () => import('../modules/org/views/executor/Index.vue'),
  },
  {
    path: '/org/executor/update/:id',
    name: 'OrgExecutorUpdate',
    component: () => import('../modules/org/views/executor/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/org/executor/create',
    name: 'OrgExecutorCreate',
    component: () => import('../modules/org/views/executor/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/org/executor-account/create',
    name: 'OrgExecutorAccountCreate',
    component: () => import('../modules/org/views/executorAccount/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/org/executor-account/update/:id',
    name: 'OrgExecutorAccountUpdate',
    component: () => import('../modules/org/views/executorAccount/Update.vue'),
    meta: { isModal: true },
  },

  // Param
  {
    path: '/param',
    name: 'Param',
    component: () => import('../modules/param/views/param/Index.vue'),
  },
  {
    path: '/param/create',
    name: 'ParamCreate',
    component: () => import('../modules/param/views/param/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/param/update/:id',
    name: 'ParamUpdate',
    component: () => import('../modules/param/views/param/Update.vue'),
    meta: { isModal: true },
  },

  // Pay
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('../modules/pay/views/pay/Index.vue'),
  },
  {
    path: '/pay/update/:id',
    name: 'PayUpdate',
    component: () => import('../modules/pay/views/pay/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/pay/create/:typeId',
    name: 'PayCreate',
    component: () => import('../modules/pay/views/pay/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/invoice/:uuid',
    name: 'PayInvoice',
    component: () => import('../modules/pay/views/invoice/Invoice.vue'),
    meta: { layout: 'start', auth: false }
  },
  {
    path: '/pay/linkinvoice/:id',
    name: 'LinkInvoice',
    component: () => import('../modules/pay/views/invoice/LinkInvoice.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },
  {
    path: '/pay/item',
    name: 'PayItem',
    component: () => import('../modules/pay/views/item/Index.vue'),
  },
  {
    path: '/pay/item/update/:id',
    name: 'PayItemUpdate',
    component: () => import('../modules/pay/views/item/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/pay/item/create',
    name: 'PayItemCreate',
    component: () => import('../modules/pay/views/item/Create.vue'),
    meta: { isModal: true },
  },

  // PayInOut
  {
    path: '/pay-in-out',
    name: 'PayInOut',
    component: () => import('../modules/payInOut/views/payInOut/Index.vue'),
  },
  {
    path: '/pay-in-out/create',
    name: 'PayInOutCreate',
    component: () => import('../modules/payInOut/views/payInOut/Create.vue'),
  },
  {
    path: '/pay-in-out/create-one/:tariffIdentifier',
    name: 'PayInOutCreateOne',
    component: () => import('../modules/payInOut/views/payInOut/CreateOne.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },

  // Pbx
  {
    path: '/pbx',
    name: 'Pbx',
    component: () => import('../modules/pbx/views/pbx/Index.vue'),
  },

  // Pbx call
  {
    path: '/pbx/call',
    name: 'PbxCall',
    component: () => import('../modules/pbx/views/call/Index.vue'),
  },

  // Pbx user
  {
    path: '/pbx/:pbxId/user',
    name: 'PbxUser',
    component: () => import('../modules/pbx/views/user/Index.vue'),
  },
  {
    path: '/pbx/:pbxId/user/update/:id',
    name: 'PbxUserUpdate',
    component: () => import('../modules/pbx/views/user/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/pbx/:pbxId/user/create/',
    name: 'PbxUserCreate',
    component: () => import('../modules/pbx/views/user/Create.vue'),
    meta: { isModal: true },
  },

  // Permission
  {
    path: '/permission',
    name: 'Permission',
    component: () => import('../modules/permission/views/permission/Index.vue'),
  },
  {
    path: '/permission/update/:id',
    name: 'PermissionUpdate',
    component: () => import('../modules/permission/views/permission/Update.vue'),
    meta: { isModal: true },
  },

  // Process
  {
    path: '/process',
    name: 'Process',
    component: () => import('../modules/process/views/process/Index.vue'),
  },
  {
    path: '/process/table',
    name: 'ProcessTable',
    component: () => import('../modules/process/views/process/Table.vue'),
  },
  {
    path: '/process/create',
    name: 'ProcessCreate',
    component: () => import('../modules/process/views/process/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/process/update/:id',
    name: 'ProcessUpdate',
    component: () => import('../modules/process/views/process/Update.vue'),
    meta: { isModal: true },
  },

  // Role
  {
    path: '/role',
    name: 'Role',
    component: () => import('../modules/role/views/role/Index.vue'),
  },
  {
    path: '/role/create',
    name: 'RoleCreate',
    component: () => import('../modules/role/views/role/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/role/update/:id',
    name: 'RoleUpdate',
    component: () => import('../modules/role/views/role/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/role/permission',
    name: 'RolePermission',
    component: () => import('../modules/role/views/role/Permission.vue'),
  },
  /////////////////////////////////////////////////////////////////////////////////
  // Project
  /////////////////////////////////////////////////////////////////////////////////

  {
    path: '/project',
    name: 'Project',
    component: () => import('../modules/project/views/Index.vue'),
  },
  {
    path: '/project/update/:id',
    name: 'ProjectUpdate',
    component: () => import('../modules/project/views/Update.vue'),
    meta: { isModal: true, modalSize: 'lg' },
  },
  {
    path: '/project/create',
    name: 'ProjectCreate',
    component: () => import('../modules/project/views/Create.vue'),
    meta: { isModal: true, modalSize: 'lg' },
  },

  /////////////////////////////////////////////////////////////////////////////////
  // Realty
  /////////////////////////////////////////////////////////////////////////////////

  // RealtyRentFlat
  {
    path: '/realty/update/:id',
    name: 'RealtyUpdate',
    component: () => import('../modules/realty/views/realty/Update.vue'),
  },
  {
    path: '/realty/rent-flat',
    name: 'RealtyRentFlat',
    component: () => import('../modules/realty/views/rentFlat/Index.vue'),
  },
  {
    path: '/realty/rent-flat/list',
    name: 'RealtyRentFlatList',
    component: () => import('../modules/realty/views/rentFlat/List.vue'),
  },
  {
    path: '/realty/rent-flat/update/:id',
    name: 'RealtyRentFlatUpdate',
    component: () => import('../modules/realty/views/rentFlat/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/realty/rent-flat/create',
    name: 'RealtyRentFlatCreate',
    component: () => import('../modules/realty/views/rentFlat/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  // RealtyRentHouse
  {
    path: '/realty/rent-house',
    name: 'RealtyRentHouse',
    component: () => import('../modules/realty/views/rentHouse/Index.vue'),
  },
  {
    path: '/realty/rent-house/list',
    name: 'RealtyRentHouseList',
    component: () => import('../modules/realty/views/rentHouse/List.vue'),
  },
  {
    path: '/realty/rent-house/update/:id',
    name: 'RealtyRentHouseUpdate',
    component: () => import('../modules/realty/views/rentHouse/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/realty/rent-house/create',
    name: 'RealtyRentHouseCreate',
    component: () => import('../modules/realty/views/rentHouse/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  // RealtyRentSpace
  {
    path: '/realty/rent-space',
    name: 'RealtyRentSpace',
    component: () => import('../modules/realty/views/rentSpace/Index.vue'),
  },
  {
    path: '/realty/rent-space/update/:id',
    name: 'RealtyRentSpaceUpdate',
    component: () => import('../modules/realty/views/rentSpace/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/realty/rent-space/create',
    name: 'RealtyRentSpaceCreate',
    component: () => import('../modules/realty/views/rentSpace/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },

  // RealtySaleFlat
  {
    path: '/realty/sale-flat',
    name: 'RealtySaleFlat',
    component: () => import('../modules/realty/views/saleFlat/Index.vue'),
  },
  {
    path: '/realty/sale-flat/update/:id',
    name: 'RealtySaleFlatUpdate',
    component: () => import('../modules/realty/views/saleFlat/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/realty/sale-flat/create',
    name: 'RealtySaleFlatCreate',
    component: () => import('../modules/realty/views/saleFlat/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  // RealtySaleHouse
  {
    path: '/realty/sale-house',
    name: 'RealtySaleHouse',
    component: () => import('../modules/realty/views/saleHouse/Index.vue'),
  },
  {
    path: '/realty/sale-house/update/:id',
    name: 'RealtySaleHouseUpdate',
    component: () => import('../modules/realty/views/saleHouse/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/realty/sale-house/create',
    name: 'RealtySaleHouseCreate',
    component: () => import('../modules/realty/views/saleHouse/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  // RealtySaleSpace
  {
    path: '/realty/sale-space',
    name: 'RealtySaleSpace',
    component: () => import('../modules/realty/views/saleSpace/Index.vue'),
  },
  {
    path: '/realty/sale-space/update/:id',
    name: 'RealtySaleSpaceUpdate',
    component: () => import('../modules/realty/views/saleSpace/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/realty/sale-space/create',
    name: 'RealtySaleSpaceCreate',
    component: () => import('../modules/realty/views/saleSpace/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },

  // Service
  {
    path: '/service',
    name: 'Service',
    component: () => import('../modules/service/views/service/Index.vue'),
  },
  {
    path: '/service/update/:id',
    name: 'ServiceUpdate',
    component: () => import('../modules/service/views/service/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/service/create',
    name: 'ServiceCreate',
    component: () => import('../modules/service/views/service/Create.vue'),
    meta: { isModal: true },
  },

  // Source
  {
    path: '/source',
    name: 'Source',
    component: () => import('../modules/source/views/source/Index.vue'),
  },
  {
    path: '/source/update/:id',
    name: 'SourceUpdate',
    component: () => import('../modules/source/views/source/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/source/create',
    name: 'SourceCreate',
    component: () => import('../modules/source/views/source/Create.vue'),
    meta: { isModal: true },
  },

  // Stat
  {
    path: '/stat/lead',
    name: 'StatLead',
    component: () => import('../modules/stat/views/lead/Index.vue'),
  },
  {
    path: '/stat/pay',
    name: 'StatPay',
    component: () => import('../modules/stat/views/pay/Index.vue'),
  },

  // Struc
  {
    path: '/struc',
    name: 'Struc',
    component: () => import('../modules/struc/views/struc/Index.vue'),
  },

  // Tariff
  {
    path: '/tariff',
    name: 'Tariff',
    component: () => import('../modules/tariff/views/tariff/Index.vue'),
  },
  {
    path: '/tariff/:id',
    name: 'TariffShow',
    component: () => import('../modules/tariff/views/tariff/Show.vue'),
    meta: { isModal: true },
  },
  {
    path: '/tariff/update/:id',
    name: 'TariffUpdate',
    component: () => import('../modules/tariff/views/tariff/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/tariff/create',
    name: 'TariffCreate',
    component: () => import('../modules/tariff/views/tariff/Create.vue'),
    meta: { isModal: true },
  },
  {
    path: '/tariff/option',
    name: 'TariffOption',
    component: () => import('../modules/tariff/views/option/Index.vue'),
  },
  {
    path: '/tariff/option/update/:id',
    name: 'TariffOptionUpdate',
    component: () => import('../modules/tariff/views/option/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/tariff/option/create',
    name: 'TariffOptionCreate',
    component: () => import('../modules/tariff/views/option/Create.vue'),
    meta: { isModal: true },
  },

  // Task
  {
    path: '/task',
    name: 'Task',
    component: () => import('../modules/task/views/task/Index.vue'),
  },
  {
    path: '/task/update/:id',
    name: 'TaskUpdate',
    component: () => import('../modules/task/views/task/Update.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },
  {
    path: '/task/create',
    name: 'TaskCreate',
    component: () => import('../modules/task/views/task/Create.vue'),
    meta: { isModal: true, modalSize: 'xl' },
  },

  // User
  {
    path: '/user',
    name: 'User',
    component: () => import('../modules/user/views/user/Index.vue'),
  },
  {
    path: '/user/:id',
    name: 'UserShow',
    component: () => import('../modules/user/views/user/Show.vue'),
    meta: { isModal: true },
  },
  {
    path: '/user/update/:id',
    name: 'UserUpdate',
    component: () => import('../modules/user/views/user/Update.vue'),
    meta: { isModal: true },
  },
  {
    path: '/user/create',
    name: 'UserCreate',
    component: () => import('../modules/user/views/user/Create.vue'),
    meta: { isModal: true },
  },

  // Wazzup
  {
    path: '/wazzup',
    name: 'Wazzup',
    component: () => import('../modules/app/components/Wazzup/AppWazzupView.vue'),
    meta: { isModal: true, modalSize: 'md' },
  },
  {
    path: '/wazzup/global',
    name: 'WazzupGlobal',
    component: () => import('../modules/app/views/wazzup/Global.vue'),
    meta: { isModal: false },
  },
  {
    path: '/app/wazzup/integration',
    name: 'WazzupIntegration',
    component: () => import('../modules/app/views/wazzup/Integration.vue'),
    meta: { isModal: true },
  },
];

const router = createRouter({ routes });

export default router;
